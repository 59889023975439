'use client'
import Link from 'next/link'
import {Button} from "@nextui-org/react";

interface props {
  isAuth: boolean;
}

export default function AuthActionButton({ isAuth }: props) {
  return (
    <div className='mt-10 justify-center items-center'>
      {isAuth ? (
        <Link href={'/knowledge-group'}>
          <Button radius='none' className='text-white flex-auto px-16 bg-slate-900 border hover:bg-slate-600'>
            Query Now
          </Button>
        </Link>
      ) : (
        <Link href={'/sign-up'}>
          <Button radius='none' className='text-white flex-auto px-16 bg-slate-900 border hover:bg-slate-600'>
            Get Started
          </Button>
        </Link>
      )}
    </div>
  );
}