import React from 'react'

type Props = {
    containerWidth: string;
    containerHeight: string;
    imageWidth: string;
    imageHeight: string;
    imagePath: string;
    imageFit: string;
    children?: React.ReactNode | null | undefined;
    label?: string | null
}

const UseCaseItem = ({ containerHeight, containerWidth, imageHeight, imageWidth, imageFit,imagePath,children, label }: Props) => {
    return (
        <div className={`flex flex-col  md:${containerWidth} md:${containerHeight} w-full items-center justify-center`}>
            <section className='glass_bg' >
                <img className={`  ${imageFit} rounded-lg md:${imageHeight} md:${imageWidth}  mx-auto`} src={imagePath} alt="Background Image" />
                {children}
            </section>
        </div>
    )
}

export default UseCaseItem