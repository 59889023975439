'use client'
import React, { useEffect, useState } from 'react'
import { Old_Standard_TT } from 'next/font/google'


type Props = {}

const oldStandard = Old_Standard_TT({ weight: '400', subsets: ['latin'] })


const ChatPreview = (props: Props) => {


    useEffect(() => {
        const animateElements = document.querySelectorAll('.posRight,.posLeft');
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && entry.intersectionRatio > 0.4) {
                    entry.target.classList.add('show');
                } else {
                    entry.target.classList.remove('show');
                }
            });
        }, { threshold: 0.4 });

        animateElements.forEach((el) => {
            observer.observe(el)
        })
        return () => {
            animateElements.forEach((el) => {
                observer.unobserve(el);
            });
        };
    }, [])

    return (
        <>
            <div className='bg-slate-900 rounded-md text-black flex flex-col border-none shadow-2xl items-center justify-center'>
                <div className='flex flex-col p-5 gap-5'>
                    <div className='relative rounded-md w-80 p-5 text-lg md:text-2xl inline-flex left-[-3vw] font-light text-center bg-slate-50 bg-opacity-20 text-neutral-300 xl:w-[25rem] posLeft shadow-lg shadow-slate-700 ' style={oldStandard.style} >
                        <p>Provide the key points from all documents about the rate hike.</p>
                    </div>
                    <div className='relative rounded-md w-[80%] md:w-[25rem] p-5 text-base md:text-2xl flex flex-col gap-3 font-light bg-slate-50 bg-opacity-10 text-neutral-100 ml-auto lg:right-[-5vw] xl:right-[-3vw] posRight shadow-lg shadow-slate-700'>
                        <p className='text-neutral-300'>Sure! Below are the key points to about the rate hike.</p>
                        <p className='text-neutral-300 '>1. The FOMC kept the
                        <span className='text-white font-medium'> benchmark interest rate unchanged</span>.</p>
                        <p className='text-neutral-300 '>2. The Fed expects <span className='text-white font-medium'>higher interest rates in 2024 and 2025</span>  due to a strong U.S. economy and inflation.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChatPreview


// <div className='flex-1 w-full h-full p-10 flex-col  '>
// <div className=' py-20 px-5 rounded-md '>

//   <div className='flex-row inline-flex items-center '>
//     {/* <img src={'/images/document.svg'} className='w-52 h-52' />
//     <img src={'/images/arrow.svg'} className='w-20 h-28 mx-5' /> */}
//     <div className='flex flex-col mx-5 gap-2'>

//       <div className='border border-black rounded-sm w-64 text-center p-3 inline-flex items-center justify-center'>
//         <SparklesIcon size={20} fill='#4462E5' color='#4462E5' className='' />
//         <span className='flex-1'>
//           Summarise the findings of the
//         </span>
//       </div>
//       <div className='border border-black rounded-sm w-64 text-center p-3 inline-flex items-center justify-center'>
//         <SparklesIcon size={20} fill='#4462E5' color='#4462E5' className='' />
//         <span className='flex-1'>
//         </span>
//       </div>
//       <div className='border border-black rounded-sm w-64 text-center p-3 inline-flex items-center justify-center'>
//         <SparklesIcon size={20} fill='#4462E5' color='#4462E5' className='' />
//         <span className='flex-1'>

//         </span>
//       </div>
//     </div>
//   </div>
//   <div className='flex flex-row items-center justify-center text-center  gap-28'>

//     <p className='flex-1'>
//       Insights
//     </p>
//   </div>
// </div>

// </div>