'use client'
import { message } from '@/lib/db/schema'
import { Tooltip } from '@nextui-org/react'
import { FileTextIcon, InfoIcon } from 'lucide-react'
import { Old_Standard_TT } from 'next/font/google';
import React, { useEffect } from 'react'

interface reference {
    data: string;
    pageNumber: number;
}


const oldStandard = Old_Standard_TT({ weight: '400', subsets: ['latin'] })


const ReferencePreview = () => {
    const references: reference[] = [
        { data: "FOMC_Rate_Hike.pdf", pageNumber: 3 },
        { data: "Fed_Sees_Rates_Staying_High_Bloomberg.pdf", pageNumber: 5 },
        { data: "Fed_rate_cuts_YNews.pdf", pageNumber: 20 },
    ];
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && entry.intersectionRatio > 0.7) {
                    entry.target.classList.add('show')
                } else {
                    entry.target.classList.remove('show')
                }
            })
        }, { threshold: 0.7 })
        const animateElements = document.querySelectorAll('.animateElement')
        animateElements.forEach((el) => observer.observe(el))
    }, [])


    return (
        <>
            <div className='animateElement w-[95%]'>
                {/* <div className='absolute bg-slate-800 bg-opacity-70 ml-30 mt-10 rounded-none h-[14rem] max-h-[14rem] w-[30rem] lg:w-[32rem] 3xl:w-[38rem] 4xl:w-[40rem] -z-10 translate-x-[-3rem] translate-y-[2rem] 2xl:h-[12rem] shadow-lg' /> */}
                <div className='absolute bg-slate-500 bg-opacity-30 ml-16 mt-5  rounded-md h-[14rem] max-h-[14rem] w-[25rem] lg:w-[30rem] 3xl:w-[38rem] 4xl:w-[40rem] -z-10 translate-x-[-3rem] translate-y-[2rem] 2xl:h-[12rem] shadow-lg hidden md:flex' />
                <div className='absolute bg-slate-700 bg-opacity-60 ml-36 mt-[-4rem] rounded-md h-[14rem] max-h-[14rem] w-[28rem] lg:w-[30rem] 3xl:w-[38rem] 4xl:w-[40rem] -z-10 translate-x-[-3rem] translate-y-[2rem] 2xl:h-[12rem] shadow-lg hidden md:flex' />

                <div className='flex flex-col items-start justify-center bg-slate-900 ml-5 xl:ml-10 text-neutral-300 mt-5 rounded-md mb-10 w-full shadow-2xl shadow-slate-900'>
                    <div className='w-full border-b p-2 bg-slate-800 rounded-t-md'>
                        <div className=' flex-row items-center justify-center text-center'>
                            <div className='inline-flex gap-2'>
                                <div className='text-xl font-thin'>
                                    References
                                </div>

                                <Tooltip content="References are ranked based on relevance score." showArrow={true} color='foreground'>
                                    <InfoIcon width={16} height={16} className='mt-1' />
                                </Tooltip>
                            </div>


                        </div>
                    </div>
                    <div className='p-5 w-full  text-md md:text-lg font-light leading-10'>
                        <div className='overflow-y-auto max-h-40 '>
                            <table className='mt-1 md:w-full '>
                                {Array.isArray(references) && (references).map((refData: reference, refIdx: number) =>
                                    <tr key={refIdx} className='p-2 '>
                                        <td className='inline-flex flex-wrap	 justify-center text-center items-center'>
                                            <FileTextIcon className='mr-3' strokeWidth={1} />
                                            <span className=' md:text-wrap w-[3vw]'>
                                                {refData.data}
                                            </span>
                                        </td>
                                        <td className='hidden md:table-cell'> Page {refData.pageNumber}</td>
                                    </tr>
                                )}
                            </table>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default ReferencePreview