'use client'
import React from 'react'
import { Button } from './ui/button'
import Link from 'next/link'
import { ArrowBigRight, ArrowRight } from 'lucide-react'
import { UserButton } from '@clerk/nextjs'

interface props {
    isAuth: boolean
}

const Navbar = ({ isAuth }: props) => {
    return (
        <div className='sticky md:h-20 h-10 pt-14 md:pt-10 p-5 md:mx-20 flex flex-row items-center justify-start gap-10'>
            <div className='grow w-30 shrink-0 min-w-10 '>
                <img src={'/images/eqsearch_dark.svg'} alt='logo' className='p-3 mt-2 max-h-20 object-cover' />
            </div>

            <div className=''>
                <div className='flex flex-row gap-10 w-full'>
                    {isAuth ?
                        <>
                            <Link href={'/knowledge-group'} >
                                <Button variant='minimal' className='hidden sm:block text-white flex-auto px-16 hover:bg-slate-600'>
                                    Query Now
                                </Button>
                            </Link>
                            <UserButton />
                        </> :
                        <>
                            <Link href={'sign-in'} className='m-2 hover:bg-slate-600'>
                                <p className='text-white underline underline-offset-8 ml-14 md:ml-0 hover:text-gray-300'>Login</p>
                            </Link>

                            <Link href={'/sign-up'} >
                                <Button variant='minimal' className='hidden sm:block  text-white flex-auto md:px-16 hover:bg-slate-600'>
                                    Get Started
                                </Button>
                            </Link>
                            <UserButton />

                        </>
                    }


                </div>
            </div>

        </div >
    )
}

export default Navbar