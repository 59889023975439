'use client'
import { cn } from '@/lib/utils'
import { Briefcase } from 'lucide-react'

import React, { useCallback, useEffect } from 'react'
import UseCaseItem from './UseCaseItem'

type Props = {}

const UseCasePreview = () => {
    const useCaseImage = ["/images/summarise_usecase.svg", '/images/usecase_cover.svg', '/images/usecase_bg.svg', '/images/summarise_cover_no_bg.svg', '/images/query_usecase.svg']
    const onScroll = useCallback((event: Event) => {
        const htmlElement = document.documentElement
        const targetElement = document.getElementById('zoom') as HTMLElement
        // const targetElement2 = document.getElementById('scroll') as HTMLElement
        // const targetElement3 = document.getElementById('cover') as HTMLElement

        const scrollTop = htmlElement.scrollTop
        const vh = window.innerHeight / 100
        const vw = window.innerWidth / 100

        const start = 230 * vh
        const bottom = 260 * vh

        //275 to 575 (height of the inner div is 30)
        const bottom2 = bottom + (50 * vh)

        if (scrollTop >= start && scrollTop <= bottom) {
            //scale from 0.8 to 1
            const scale = Math.min((0.9 + 0.1 * ((scrollTop - start) / (bottom - start))), 1)
            targetElement.style.transform = `scale(${scale})`
            // console.log('translate is', ((scrollTop - start) / (bottom - start)) )
        }
        // if (scrollTop >= bottom && scrollTop <= bottom2) {
        //     // Translate from right to left

        //     const translate = Math.min(Math.max((scrollTop - bottom) / (bottom2 - bottom), 0), 1) * 100 * vh;

        //     // const opacity = Math.min(( 0.3+4 * ((scrollTop - bottom) / (bottom2 - bottom))), 1)
        //     function scaleWithSine(progress:any) {
        //         return 0.5 + 0.5 * Math.sin(progress * Math.PI * 3);
        //     }
        //     // Assuming scrollTop, bottom, bottom2, vh are defined elsewhere in your code
        //     const progress = Math.min(Math.max((scrollTop - bottom) / (bottom2 - bottom), 0), 1);
        //     // Use the scaleWithSine function for opacity
        //     const opacity = scaleWithSine(progress);
        //     targetElement2.style.transform = `translateY(${-1 * translate}px)`;
        //     targetElement2.style.opacity = `${opacity}`;

        //     if (scrollTop >= (bottom2-20*vh) && scrollTop <= bottom) {
        //         const opacity = Math.min((0.7 + 0.3 * ((scrollTop - start) / (bottom - start))), 1)
        //         console.log('opacity is ', opacity)
        //         targetElement3.style.opacity = `${1-opacity}`;

        //     }
        // }



    }, []);

    useEffect(() => {
        //add eventlistener to window
        window.addEventListener("scroll", onScroll, true);
        // remove event on unmount to prevent a memory leak with the cleanup
        return () => {
            window.removeEventListener("scroll", onScroll, true);
        }
    }, []);





    return (
        <><div className='flex flex-col items-center text-left text-white bg-slate-950 scale-90 w-[100vw] h-[280vh] rounded-sm ' id='zoom'>
            {/* <div className='sticky top-0 h-[100vh] w-[100vw]'>
                <img src={useCaseImage[0]} className='h-full w-full object-cover' />
                <div className='absolute inset-0 bg-black opacity-70' id='cover'></div>
            </div> */}
            <div className='sticky top-16 flex flex-col items-center text-left text-white snap-end h-[200vh] md:h-[170vh] lg:w-[90dvw] 2xl:w-[85dvw] mx-auto' >
                {/* <p>Scroll Percentage: {scrollPercentage.toFixed(2)}%</p> */}

                <div className=' flex flex-col font-light text-3xl  sm:text-6xl px-8 md:px-14 pt-14 pb-3 text-center items-center justify-center' id='scroll'>
                    Leverage next-level intelligence to
                    <div className="bg-gradient-to-r from-sky-400 to-blue-500 text-transparent bg-clip-text mt-5 inline-flex justify-center items-center gap-3 pb-1" >
                        Boost your workflow
                        <svg width="0" height="0">
                            <linearGradient id="blue-gradient" x1="0%" y1="0%" x2="100%" y2="100%">
                                <stop stopColor="#61B8F0" offset="0%" />
                                <stop stopColor="#4A7AE2" offset="100%" />
                            </linearGradient>
                        </svg>
                        <Briefcase size={50} strokeWidth={1.2} style={{ stroke: "url(#blue-gradient)" }} className=' hidden sm:block' />
                    </div>



                </div>
                <section className='relative flex flex-col items-center justify-end'>
                    {/* Background Image */}
                    <img className={`z-10 image-fit rounded-lg h-full w-full`} src={useCaseImage[2]} alt="Background Image" />

                    {/* Top Image */}
                    <img className={`absolute z-20 mx-auto image-fit rounded-lg h-[90%] w-[90%]`} src={useCaseImage[3]} alt="Foreground Image" />

                    {/* Your other components go here, if any */}
                </section>




                {/* two use cases */}
                <section className='flex flex-col mt-10 gap-5 md:flex-row'>
                    <UseCaseItem containerHeight='h-[50vh]' containerWidth='w-[42vw]' imageHeight='h-[25vh]' imageWidth='w-[27vw]' imageFit='object-fit' imagePath={useCaseImage[0]}  >
                        <div>
                            <section className='text-4xl font-medium z-20 text-start mt-5'>
                                Summarise your documents
                            </section>
                            <section className='mt-2 font-light text-lg text-neutral-300'>
                                Drop your pdf(s), chat to get summaries of the topic.
                            </section>
                        </div>
                    </UseCaseItem>
                    <UseCaseItem containerHeight='h-[50vh]' containerWidth='w-[42vw]' imageHeight='h-[25vh]' imageWidth='w-[27vw]' imageFit='object-fit' imagePath={useCaseImage[4]}  >
                        <div>
                            <section className='text-4xl font-semibold z-20 text-start mt-5'>
                                Query information from your pdf
                            </section>
                            <section className='mt-2 font-light text-lg text-neutral-300'>
                                Query any information of your pdf, save you reading and searching time.
                            </section>
                        </div>
                    </UseCaseItem>

                </section>


                {/* three usecases */}
                {/* <section className='flex flex-row mt-10 gap-3'>
                    <UseCaseItem containerHeight='h-[50vh]' containerWidth='w-[28vw]' imageHeight='h-[22vh]' imageWidth='w-[26vw]' imagePath={useCaseImage[1]}  >
                        <div>
                            <section className='text-4xl font-semibold z-20 text-start mt-5'>
                                Summarise your documents
                            </section>
                            <section className='mt-2 font-light text-lg text-neutral-300'>
                                Drop your pdf(s), chat to get summaries of the topic.
                            </section>
                        </div>
                    </UseCaseItem>
                    <UseCaseItem containerHeight='h-[50vh]' containerWidth='w-[28vw]' imageHeight='h-[22vh]' imageWidth='w-[26vw]' imagePath={useCaseImage[0]}  >
                        <div>
                            <section className='text-4xl font-semibold z-20 text-start mt-5'>
                                Usecase 2
                            </section>
                            <section className='mt-2 font-light text-lg text-neutral-300'>
                                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Repudiandae repellat magni vitae tenetur libero error nostrum delectus ullam asperiores odit!
                            </section>
                        </div>
                    </UseCaseItem>

                    <UseCaseItem containerHeight='h-[50vh]' containerWidth='w-[28vw]' imageHeight='h-[22vh]' imageWidth='w-[26vw]' imagePath={useCaseImage[0]}  >
                        <div>
                            <section className='text-4xl font-semibold z-20 text-start mt-5'>
                                Usecase 3
                            </section>
                            <section className='mt-2 font-light text-lg text-neutral-300'>
                                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Repudiandae repellat magni vitae tenetur libero error nostrum delectus ullam asperiores odit!
                            </section>
                        </div>
                    </UseCaseItem>

                </section> */}



                {/* <div className='flex flex-col h-[45vh] w-[80vw] items-center'>
                    <section className=' inset-0 bg-white/30 bg-opacity-20 backdrop-blur-sm rounded-lg' />
                    <img className=" inset-5 object-cover h-[40vh] w-[75vw] rounded-lg" src={useCaseImage[1]} alt="Background Image" />
                </div> */}
            </div>
        </div>
        </>
    )
}

export default UseCasePreview