'use client'

import React, { useEffect, useState } from 'react'

type Props = {}

async function sleep(ms: number) {
    return new Promise((resolve) => {
        setTimeout(resolve, ms)
    })
}
const Typewriter: React.FC<{ text: string, speed: number }> = ({ text, speed }) => {
    const [displayText, setDisplayText] = useState<string>('');
    const [cursorBool, setDisplayCursor] = useState<boolean>(true);
    const [curCharIndex, setCurCharIndex] = useState<number>(0);

    useEffect(() => {
        const typeNextCharacter = async () => {
            while (true) {
                let curWord = text

                for (let i = 0; i <= curWord.length; i++) {
                    await sleep(speed)
                    setDisplayText(curWord.substring(0, i))
                }
                break
            }
            setDisplayCursor(false)
        };


        typeNextCharacter();
    }, [curCharIndex, text, speed]);
    return <div id="typewriter" className=' w-[70vw] break-normal leading-[1.5]'>
        {/* overflowhidden prevent growing */}

        {displayText}


        {cursorBool && <span id='cursor'>|</span>}
    </div>;

};



const Header = (props: Props) => {
    return (
        <Typewriter text="AI Powered Knowledge-base for Everyone" speed={50} />
    )
}

export default Header